<template>
  <div class="course_node_video" style="padding-left: 1px">
    <video-player
      class="video-player vjs-custom-skin"
      ref="videoPlayer"
      :playsinline="true"
      :options="playerOptions"
      @ready="playerReadied"
      @timeupdate="onPlayerTimeupdate($event)"
      @pause="onPlayerPause($event)"
      @play="onPlayerPlay($event)"
    >
    </video-player>
    <p
      class="SkipTitle cu"
      @click="skipTitle"
      :style="[{ display: time <= 5 && time > 0 ? 'block' : 'none' }]"
    >
      点击跳过片头
    </p>
    <p
      :style="[{ display: totalSecond - 5 <= time ? 'block' : 'none' }]"
      class="SkipTitle cu"
      @click="skipTrailer"
    >
      视频马上就播放完啦,点击播放下一集
    </p>
  </div>
</template>

<script>
import { videoPlayer } from "vue-video-player";
import "video.js/dist/video-js.css";
export default {
  // created(){
  //   window.addEventListener('beforeunload', e =>this.addUserVideoStatusAndLog(e))
  // },
  props: {
    gid: {
      type: Number,
    },
    cid: {
      type: Number,
    },
    vid: {
      type: Number,
    },
    playerOptions: {
      playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
      autoplay: false, // 如果为true,浏览器准备好时开始回放。
      muted: false, // 默认情况下将会消除任何音频。
      loop: false, // 是否视频一结束就重新开始。
      preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
      language: "zh-CN",
      aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
      fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
      sources: [
        {
          type: "video/mp4", // 类型
          src: "http://webxiaozhu.oss-cn-beijing.aliyuncs.com/Course/%E4%BF%AE%E6%94%B9%E5%90%8E02-%E7%8E%B0%E5%9C%A8%E4%B8%8D%E5%AD%A6BIM%E5%B0%86%E6%9D%A5%E5%8F%91%E5%B1%95%E5%A5%BD%E5%90%97.mp4", // url地址
        },
      ],
      poster:
        "http://webxiaozhu.oss-cn-beijing.aliyuncs.com/images/videoCoverasRKZ3", // 封面地址
      notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
      controlBar: {
        timeDivider: true, // 当前时间和持续时间的分隔符
        durationDisplay: true, // 显示持续时间
        remainingTimeDisplay: true, // 是否显示剩余时间功能
        fullscreenToggle: true, // 是否显示全屏按钮
      },
    },
    timer: {
      type: Number,
    },
    totalSecond: {
      type: Number,
    },
  },
  components: {
    videoPlayer,
  },
  data() {
    return {
      time: 0,
      player: "",
      // timer: 0,
      type: true,
      num: 0,
      maxTime: 0,
      consoleTime : null
    };
  },
  methods: {
    // 跳过片尾
    skipTrailer() {
      let data = {
        vid: this.vid,
        gid: this.gid,
        cid: this.cid,
      };
      this.$store.commit("overall/skipTrailerInfo", data);
    },
    // 跳过片头
    skipTitle() {
      this.playerReadied(this.$refs.videoPlayer.player, 5.1);
    },
    showVideo() {
      let videoSrc = this.playerOptions.sources.src;
      this.$refs.videoPlayer.player.src(videoSrc);
    },
    // 播放回调
    onPlayerPlay(player) {
      if (this.num == 0) {
        if (this.timer > 0)
          this.playerReadied(this.$refs.videoPlayer.player, this.timer);
        let data = {
          cid: this.cid,
          gid: this.gid,
          vid: this.vid,
          uid: this.$f.uid(),
        };
        this.Api.Center.StorageTime(data).then((res) => {
          this.num += 1;
          console.log(res);
        });
      }
      if (this.type) {
        console.log(this.timer)
        player.currentTime(this.timer);
        this.type = false;
      }
    },
    /* 获取视频播放进度 */
    onPlayerTimeupdate(player) {
      if (!this.$f.uid()) {
        this.$message.error("请先登录");
        player.pause();
        return false;
      }
      this.time = player.cache_.currentTime;
      if (this.time > this.maxTime) this.maxTime = this.time;
    },
    /* 设置视频进度 */
    playerReadied(player, val) {
      player.currentTime(val);
    },
    // onPlayerPause(player) {
    //   this.$emit("editVideoInfo", player.cache_.currentTime);
    // },
    addUserVideoStatusAndLog() {
      if (this.maxTime <= 60) return false;
      let data = {
        uid: this.$f.uid(),
        cid: this.cid,
        gid: this.gid,
        vid: this.vid,
        videoprogress: this.maxTime,
      };
      this.$emit("editVideoTime", data);
      this.Api.Center.addUserVideoStatusAndLog(data).then((res) => {
        console.log(res);
      });
    },
  },
  destroyed() {
    window.removeEventListener("beforeunload", (e) =>
      this.addUserVideoStatusAndLog(e)
    );
  },
  beforeDestroy() {
    this.addUserVideoStatusAndLog();
    // this.$emit("editVideoInfo", this.time);
  },
  watch: {
    timer: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val == 0) return;
        this.$nextTick(() => {
          this.playerReadied(this.$refs.videoPlayer.player, val);
        });
      },
    },
  },
  mounted() {
    window.addEventListener("unload", (e) => this.addUserVideoStatusAndLog(e));
  },
  beforeDestory(){
    clearTimeout(this.consoleTime)
    this.timer = null;
  },
};
</script>

<style scoped>
.course_node_video >>> .video-js .vjs-big-play-button {
  top: 45%;
  left: 45%;
}
.SkipTitle {
  position: absolute;
  bottom: 50px;
  left: 20px;
}
</style>